@use './config/' as *;
@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './showcase/showcase';
@forward './stories/story';

.waf-component {
    &.waf-listing {
        @extend %p-y-10-20;
    }
}
.waf-listing.widget-layout-09{
    &.waf-component{
        padding-top: var(--space-20);
        padding-bottom: var(--space-20);
        @extend %mb-0;
    }
}
.waf-listing {
    @extend %mr-2-neg;
    &.waf-component{
        padding-top: 0;
        padding-bottom: var(--space-20);
    }
    .layout-wrapper{
        max-width: 100%;
    }
    .waf-head{
        max-width: var(--container-max-width);
        @extend %mx-auto;
    }
    .title {
        font-size: 2.4rem;
        line-height: 1.4;
        @extend %mb-0;
        @extend %flex-column;
        &::before {
            color: var(--neutral-40);
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            background-color: hsla(var(--hsl-neutral-0)/0.2);
            @extend %mb-2;
            @extend %flex-c-c;
            @extend %border-radius-s;
        }
    }
    .head-wrap {
        align-items: flex-end;
        @extend %mb-10;
    }
    &.waf-horizontal-list {
        @include article-listing(card);
        .article{
            &-title{
                height: auto;
                margin-top: 0;
                @extend %mb-6;
            }
            &-content{
                @extend %bg-neutral-0;
                > a{
                    order: -1;
                }
            }
        }
        .title {
            &::before {
                @include icon(newspaper, 24);
            }
        }
    }
    &.waf-overlay-list,
    &.waf-horizontal-list {
        .article-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - var(--space-6));
            grid-template-columns: unset;
            overflow-x: auto;
        }
    }
  
    &.waf-overlay-list {
        .title {
            &::before {
                @include icon(photo, 24);
            }
        }
    }
    &.waf-video-list {
        overflow-x: clip;
        @extend %relative;
        &::before {
            content: "";
            rotate: 110deg;
            position: absolute;
            bottom: -14rem;
            @include bgImg('/cssimages/pattern/blue-gradient-left.png', 20% bottom, contain);
            @extend %w-100;
            @extend %h-50;
            @extend %d-block;
            @extend %zindex-pattern;
        }
        .title::before {
            @include icon(play, 24);
        }
    }
}
@include mq(col-tablet) {
   .waf-listing{
        margin-right: 0;
        .layout-wrapper{
            max-width: var(--container-max-width);
        }
        .title {
            font-size: 4rem;
            line-height: 4.8rem;
            &::before{
                margin-bottom: var(--space-4);
            }
        }
        .waf-head{
            max-width: unset;
            margin-inline: unset;
        }
        &.waf-horizontal-list,
        &.waf-overlay-list {
            .article-list {
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                overflow-x: unset;
            }
        }
        &.waf-video-list {
            overflow-x: unset;
            &::before{
                rotate: unset;
                bottom: -35rem;
                background-size: cover;
                height: calc(100% + 40rem);
                margin-inline: var(--space-2-neg);
            }
        }
   }
}
@include mq(col-lg) {
    .waf-listing{
        &.waf-horizontal-list,
        &.waf-overlay-list {
            .article-list {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }
}