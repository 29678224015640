@use '../config/' as *;
.waf-listing-stories {
    &.waf-component {
        @extend %pb-20;
    }
    .head-wrap{
        flex-direction: column;
        align-items: flex-start;
        @extend %mb-0;
    }
    .title {
        @extend %title-20;
        @extend %mb-4;
        &::before {
            color: var(--neutral-40);
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            background-color: hsla(var(--hsl-neutral-0)/0.2);
            @extend %mb-2;
            @extend %flex-c-c;
            @extend %border-radius-s;
            @include icon(trophy, 24);
        }
    }
    .desc {
        @extend %body-lb;
        @extend %neutral-70;
    }
    .waf-head {
        @extend %mb-6;
    }
    .content {
        &-list {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: auto;
            gap: var(--space-4);
            // @include card-count(1.2, var(--space-4));
            @extend %x-auto;
            @extend %mr-4-neg;
        }
        &-item {
            flex-direction: row-reverse;
            width: max-content;
            max-width: 52rem;
            @include border(1, neutral-20);
            @extend %bg-neutral-0;
            @extend %border-radius-m;
            @extend %flex;
            @extend %x-hidden;
            &:last-child {
                @extend %mr-4;
            }
            &.no-media {
                max-width: 30rem;
                .content {
                    &-body {
                        @extend %w-100;
                    }
                    &-thumbnail {
                        @extend %d-none;
                    }
                }
            }
            &.no-content {
                .content {
                    &-thumbnail {
                        @extend %w-100;
                    }
                    &-body {
                        @extend %d-none;
                    }
                }
            }
        }
        &-title {
            @extend %title-40;
            @extend %neutral-100;
        }
        &-description {
            @extend %body-l;
            @extend %neutral-100;
        }
        &-thumbnail,
        &-body {
            @extend %w-50;
            @extend %relative;
        }
        &-body {
            @extend %p-6;
            @extend %flex-column;
            @extend %gap-6;
        }
        &-meta {
            @extend %flex-n-c;
            @extend %gap-4;
        }
    }
    .meta {
        &-country {
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %text-m;
            @extend %neutral-70;
            @extend %uppercase;
            img {
                width: 1.6rem;
                aspect-ratio: 1/1;
                @extend %circle-radius;
            }
        }
        &-author {
            @extend %body-lb;
        }
    }
    .img-box {
        aspect-ratio: 7/8;
        @extend %h-100;
    }
    img {
        object-fit: cover;
        object-position: center;
    }
    .btn-link {
        @extend %text-mb;
        @extend %primary-50;
    }
    .item-type-icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@include mq(col-tablet) {
    .waf-listing-stories {
        .title{
            &::before{
                margin-bottom: var(--space-4);
            }
        }
        .content {
            &-list {
                grid-auto-flow: row;
                grid-template-columns: 1fr 1fr 1fr;
                overflow-x: unset;
                margin-right: 0;
            }
            &-item {
                max-width: unset;
                width: unset;
                &:nth-child(2),
                &:nth-child(6) {
                    grid-column: 2/4;
                }
                &:nth-child(3) {
                    grid-column: 1/3;
                }
                &.no-media {
                    max-width: unset;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing-stories {
        .layout-wrapper {
            display: grid;
            grid-template-columns: 35rem calc(100% - 41rem);
            gap: var(--space-13);
        }
    }
}